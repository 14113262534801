export default {
	namespaced: true,
	state: {
		value: '',
		result: null,
		showResults: false,
		showMoreDetails: '',
		downloadingSpreadsheet: ''
	},
	getters: {},
	mutations: {},
	actions: {
		resultKey(result, i) {
			return `${result.VendorId}-${result.StyleNumber}-${result.Batch}-${result.FormattedSubmittedAt}-${i}`
		}
	}
}
