import { createStore } from 'vuex'
import breakpoints from '@/store/modules/breakpoints'
import search from '@/store/modules/search'

export default createStore({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		breakpoints,
		search
	}
})
