import { useGrid } from 'vue-screen'

export default {
	namespaced: true,
	state: {
		grid: useGrid('bootstrap')
	},
	getters: {
		current: state => state.grid.breakpoint,
		xsOrHigher: state => state.grid.xs,
		smOrHigher: state => state.grid.sm,
		umdOrHigher: state => state.grid.md,
		lgOrHigher: state => state.grid.lg,
		xlOrHigher: state => state.grid.xl,
		xxlOrHigher: state => state.grid.xxl,
		upToXs: (state, getters) => getters['current'] === 'xs',
		upToSm: (state, getters) => getters['upToXs'] || getters['current'] === 'sm',
		upToMd: (state, getters) => getters['upToSm'] || getters['current'] === 'md',
		upToLg: (state, getters) => getters['upToMd'] || getters['current'] === 'lg',
		upToXl: (state, getters) => getters['upToLg'] || getters['current'] === 'xl',
		upToXxl: (state, getters) => getters['upToXl'] || getters['current'] === 'xxl',
		isDesktop: (state, getters) => getters['xlOrHigher'],
		isMobile: (state, getters) => getters['upToLg']
	}
}
